
#fadein{
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
}
.BrainhubCarousel {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 300px;
    margin-top: 10px;
}
.BrainhubCarousel__arrows {
    position: relative;
    padding: 21px;
    border: none;
    overflow: hidden;
    outline: 0;
    font-size: 0;
    line-height: 0;
    background-color:unset !important;
    height: 75%;
}
.progress-bar{
    height: unset !important;
    border-radius: unset !important;
}
iframe{
height: 98vh;
width: 100%;
}
.swal2-content{
    padding: unset !important;
}

.w-100{
    width: 100% !important;
    padding: 10px;
}
.input-form{
    margin: 15px 0;
    color: #ffffff;
}
.input-form .title-input{
    font-size: 20px;
}
.input-form > input{
    width: 100%;
    height: 50px;
    font-size: 30px;
    /* padding-left: 20px; */
    background-color: unset !important;
    outline: unset;
    border:solid 1px #000000;
    border-radius: 50px;
    color: #081c97;
    background-color: #ffffff !important;
    text-align:center;
    
}
.disabel{
    background-color: gray !important;
}

.swal2-popup{
    padding: 0.25em !important; 
}
#sub-share{
    display: flex;
    justify-content: center;
}
ol, ul {
    padding-left: 0rem !important;
}
hr{
    border: 1px solid red;
}
span, li {
    font-size: 20px !important;
    margin: 0 1px;
}
.swal2-backdrop-show{
    z-index: 2000 !important;
}
/* .app-conten input{
    font-size: 2em;
} */


.load-screen {
    width: 100%;
    height: 100%;
    /* background: url("./asset/images/neeyai/octo-loader.gif") no-repeat center center #fff; */
    position: fixed;
    opacity: 0.5;
    background-size: contain;
    /* background-color: #000000; */
    z-index: 1000;
}

.top-bar{
    width: 100%;
    padding: 0px;
    margin: auto;
    position: absolute;
    top: 0%;
    left: 50%;
    background-color: #b37814;
    transform: translate(-50%, -50%);
    z-index: 100;
    height: 52%;
}
.bt-bar{
    width: 100%;
    padding: 0px;
    margin: auto;
    position: absolute;
    top: 100%;
    left: 50%;
    background-color: #b37814;
    transform: translate(-50%, -50%);
    z-index: 100;
    height: 12%;
}
.top-bar-img{
    width: 100%;
    padding: 0px;
    margin: auto;
    position: absolute;
    top: 0%;
    left: 50%;
    background-color: #b37814;
    background-image: url("https://theoldsiam.co.th/images/loykrathong/loykrathong2022/loykratong_bg.jpg");
    transform: translate(-50%, -50%);
    z-index: 101;
    height: 46%;
}
.img-c-t{
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* height: 100%; */
    width: 90%;
}


/* @keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
} */


@media (max-width: 575.98px) {
    .content-group-btn{
        position: fixed;
        z-index: 15;
        bottom: 0;
        top: unset;
    }
    .content-group-btn img{
        display: inline-block;
        width: 50%;
    }

    .cover-profile{
        height:200px !important;
      }
}

@media (max-width: 767.98px) {

}

@media (max-width: 991.98px) {

}

@media (max-width: 1199.98px) {

}

.rcv-product-image-container-custom > img{
    height: unset !important;
}

.rcv-product-image-container-custom{
    width: unset !important;
}

.rcv-product-name-custom{
    color: #000000 !important;
}

.rcv-product-description-custom{
    color: #636363 !important;
    overflow: unset !important;
}
